import React from 'react'
import Helmet from 'react-helmet'

const SecurityHeader = () => (
    <Helmet>
        <meta http-equiv="X-Frame-Options" content="DENY" />
        <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
        <meta http-equiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains" />
        <meta http-equiv="X-Content-Type-Options" content="nosniff" />
        <meta http-equiv="X-Download-Options" content="noopen" />
        <meta http-equiv="X-Permitted-Cross-Domain-Policies" content="none" />
    </Helmet>
);

export default SecurityHeader;